import { ShaderPass } from 'postprocessing';
import { ShaderMaterial, SRGBColorSpace, WebGLRenderTarget } from 'three';
import {
    defineComponent,
    inject,
    onBeforeUnmount,
    onMounted,
    provide,
    reactive,
    ref,
    watch,
} from 'vue';

import glslBlendScreen from '@resn/gozer-glsl/color/blend/screen.glsl';
import { simpleVs } from '@resn/gozer-three';
import { useEffectComposer, usePane, useRaf, useViewportResize } from '@resn/gozer-vue';

const postKey = 'postProcessing';

export const usePostProcessing = () => {
    const postProcessing = inject(postKey, { fbo: null, render: null, pass: null, composer: null });
    return postProcessing;
};

export const PostProcessing = defineComponent({
    setup() {
        const propsPost = reactive({});
        const enabled = ref(true);
        const { renderer } = inject('renderer');

        const fbo = new WebGLRenderTarget(1, 1, {
            colorSpace: SRGBColorSpace,
        });

        const shader = new ShaderMaterial({
            uniforms: {
                tMap: { value: fbo.texture },
                uTime: { value: 0 },
            },
            vertexShader: simpleVs,
            fragmentShader: /* glsl */ `
                varying vec2 vUv;
                uniform sampler2D tMap;
                uniform float uTime;
                ${glslBlendScreen}
                void main() {
                    vec2 st = vUv;
                    vec3 grad = mix(vec3(0.29, 0.95, 0.63), vec3(0.97, 0.54, 0.88), st.y);
                    vec4 color;
                    color = texture2D(tMap, st);
                    // color.rgb /= color.a;
                    gl_FragColor = vec4(color);
                    // gl_FragColor = vec4(vec3(vUv.y), 1.);
                }`,
            transparent: true,
            defines: {},
        });

        const composer = useEffectComposer({ autoRenderToScreen: true });
        const passDefault = new ShaderPass(shader);

        onMounted(() => {
            composer.addPass(passDefault);
            resize(viewport);
        });

        const resize = ({ width, height }) => {
            const dpr = renderer.getPixelRatio();
            fbo.setSize(width * dpr, height * dpr);
        };
        const viewport = useViewportResize(({ width, height }) => resize({ width, height }), true);

        watch(propsPost, () => {});
        watch(
            enabled,
            (val) => {
                composer.enabled = val;
            },
            { immediate: true }
        );

        useRaf(({ delta }) => {
            shader.uniforms.uTime.value += delta;
        });

        const render = () => {
            composer?.render();
        };

        provide(postKey, {
            composer,
            fbo,
            render,
            propsUniforms: propsPost,
            setEnabled(val) {
                enabled.value = val;
            },
            get enabled() {
                return enabled.value;
            },
        });

        onBeforeUnmount(() => {
            fbo?.dispose();
            fbo?.texture.dispose();
            shader?.dispose();
        });

        usePane(
            [
                { value: propsPost, options: { min: 0, max: 1, step: 0.001 } },
                { name: 'enabled', value: enabled },
            ],
            {
                title: 'Post Processing',
                expanded: true,
            }
        );
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }
        return null;
    },
});
